import React from "react";
import { ICompany } from "modules/companies/types";
import { all } from "modules/companies/services";

const useCompanies = (filterCompanies: boolean = true) => {
  const [companies, setcompanies] = React.useState<ICompany[]>([]);

  const getAll = React.useCallback(async () => {
    const { data } = await all(filterCompanies);
    setcompanies(data);
  }, [filterCompanies]);

  React.useEffect(() => {
    getAll();
  }, [getAll]);

  return { companies };
};

export { useCompanies };
