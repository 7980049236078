import styled from "@emotion/styled";

export const Main = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background: rgba(0, 0, 0, 0.8);
  position: relative;
`;

export const ButtonClose = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  z-index: 10;

  svg {
    fill: #fff;
    width: 50px;
    height: 50px;
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.6));
  }
`;

export const Grid = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  left: 0;
  iframe {
    .video_wrapper {
      .videos {
        .video {
          background: #000 !important;
          fill: #000 !important;
        }
      }
    }
  }
`;

export const Loading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 24px;
    color: #fff;
  }
`;
