import React from "react";
import { Modal, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

import * as Styled from "./styles";

interface IProps {
  videos: string[];
  open: boolean;
  loading: boolean;
  onClose: () => void;
}

const VideoGrid: React.FC<IProps> = ({ videos, open, onClose, loading }) => {
  React.useEffect(() => {
    if (open) document.body.requestFullscreen();
  }, [open]);

  return (
    <Modal open={open} onClose={() => onClose()}>
      <Styled.Main>
        <Styled.ButtonClose
          onClick={() => {
            onClose();
            document.exitFullscreen();
          }}
        >
          <Close />
        </Styled.ButtonClose>

        <>
          {!loading ? (
            <Styled.Grid>
              {videos.map((video, index) => (
                <video width="100%" controls key={`vd${index}`} autoPlay muted>
                  <source src={video} type="video/mp4" />
                </video>
              ))}
            </Styled.Grid>
          ) : (
            <Styled.Loading>
              <Typography>Aguarde...</Typography>
            </Styled.Loading>
          )}
        </>
      </Styled.Main>
    </Modal>
  );
};

export { VideoGrid };
