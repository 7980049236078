import React from "react";
import { firstAccess } from "shared/services";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Box, TextField, Alert, Button, Typography } from "@mui/material";
import { useIntl, defineMessages } from "react-intl";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "config/routes";
import { FormBox } from "modules/auth/components/FormBox";

type FieldValues = {
  password: string;
  passwordConfirmation: string;
};

const messages = defineMessages({
  title: { id: "password.confirmation" },
  subtitle: { id: "enter.new.password" },
  email: { id: "email" },
  password: { id: "password" },
  PasswordConfirm: { id: "password.confirm" },
  send: { id: "send" },
  back: { id: "back" },
  required: { id: "required" },
  success: { id: "password.updated" },
  loading: { id: "loading" },
});

const validationSchema = Yup.object().shape({
  password: Yup.string().required("Campo obrigatório"),
  passwordConfirmation: Yup.string()
    .required("Campo obrigatório")
    .oneOf([Yup.ref("password")], "Senha deve ser compátivel"),
});

const FirstLogin: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>(false);
  const { formatMessage } = useIntl();

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  const { register, formState, handleSubmit } =
    useForm<FieldValues>(formOptions);
  const { errors } = formState;

  const goBack = React.useCallback(() => navigate(ROUTES.LOGIN), [navigate]);

  const onSubmit = async (data: FieldValues) => {
    setLoading(true);
    const { status } = await firstAccess(data);
    if (status === 200) {
      window.location.href = "/";
    } else {
      setLoading(false);
      setSuccess(false);
    }
  };

  return (
    <FormBox
      title={formatMessage(messages.title)}
      subtitle={formatMessage(messages.subtitle)}
    >
      <div className="childrenForm">
        {success && (
          <Alert severity="success" sx={{ marginTop: 2 }}>
            {formatMessage(messages.success)}
          </Alert>
        )}
        <Box
          onSubmit={handleSubmit(onSubmit)}
          component="form"
          sx={{
            pt: 4,
            width: "100%",
          }}
        >
          <Typography>
            Olá! Como este é o seu primeiro acesso, altere a sua senha para
            continuar:
          </Typography>
          <br />
          {!success && (
            <>
              <TextField
                {...register("password")}
                label={formatMessage(messages.password)}
                type="password"
                name="password"
                id="password"
                error={Boolean(errors.password)}
                helperText={
                  errors.password?.type === "required" &&
                  formatMessage(messages.required)
                }
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                {...register("passwordConfirmation")}
                label={formatMessage(messages.PasswordConfirm)}
                type="password"
                name="passwordConfirmation"
                id="passwordConfirmation"
                error={Boolean(errors.passwordConfirmation ? "inválido" : "")}
                helperText={errors.passwordConfirmation?.message}
                fullWidth
                sx={{ mb: 2 }}
              />

              <Button
                variant="contained"
                type="submit"
                size="large"
                fullWidth
                disabled={loading}
              >
                {formatMessage(loading ? messages.loading : messages.send)}
              </Button>
            </>
          )}
          {success && (
            <Button
              onClick={goBack}
              variant="text"
              size="large"
              fullWidth
              sx={{ mt: 2 }}
            >
              {formatMessage(messages.back)}
            </Button>
          )}
        </Box>
      </div>
    </FormBox>
  );
};

export { FirstLogin };
