import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { ICompanyDashboardItem } from "modules/companies/types";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom" as const,
    },
    title: {
      display: true,
      text: "Últimos 30 dias",
    },
  },
};

interface IProps {
  values: ICompanyDashboardItem;
}

const DashboardChart: React.FC<IProps> = ({ values }) => {
  const labels = values.days;

  const data = {
    labels,
    datasets: [
      {
        label: "GB",
        data: values.gb,
        backgroundColor: "rgba(19, 144, 203, .5)",
      },
      {
        label: "Arquivos",
        data: values.files,
        backgroundColor: "rgba(117, 117, 117, 0.5)",
      },
    ],
  };

  //@ts-ignore
  return <Bar options={options} data={data} />;
};

export { DashboardChart };
