import React from "react";
import { TextField, Select, MenuItem, InputLabel } from "@mui/material";
import { useSnackbarConsumer } from "shared/providers/Snackbar";
import { useNavigate, useParams } from "react-router-dom";
import { useIntl, defineMessages } from "react-intl";
import { ROUTES } from "config/routes";
import { useForm, Controller } from "react-hook-form";

import useCrud from "shared/hooks/use-crud";
import { setURI, store } from "shared/services/crud";
import { ILiveCamera } from "modules/live-cameras/types";
import { Form } from "shared/components/Form";
import { useCompanies } from "shared/hooks/useCompanies";
import { ICompany } from "modules/companies/types";

const messages = defineMessages({
  titleCreate: { id: "live.cameras.create" },
  titleEdit: { id: "live.cameras.edit" },
  create: { id: "create" },
  save: { id: "save" },
  required: { id: "required" },
  success: { id: "crud.register.success" },
  error: { id: "crud.register.error" },
  companyField: { id: "user.company" },
});

interface IForm {
  id?: number;
  company_id: number;
  url: string;
}

const initialValues: ILiveCamera = {
  url: " ",
  company_id: 0,
};

const FormLiveCameras: React.FC = () => {
  const uri = "/live-cameras";
  setURI(uri);
  const navigate = useNavigate();
  const { id } = useParams();
  const { getById, editData } = useCrud<ILiveCamera>({
    uri,
    initialValues,
  });
  const { companies } = useCompanies();
  const { formatMessage } = useIntl();
  const { openSnackbar } = useSnackbarConsumer();
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
  } = useForm<IForm>({
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  React.useEffect(() => {
    if (id) getById(Number(id));
  }, [getById, id]);

  React.useEffect(() => {
    const formData = {
      url: editData.url,
      company_id: editData.company_id,
    };
    reset(formData);
  }, [editData, reset]);

  const onSubmit = async (values: IForm) => {
    const data = { ...values };
    if (id) data.id = parseInt(id);
    return store<ILiveCamera>(data)
      .then(() => {
        openSnackbar({
          message: formatMessage(messages.success),
          severity: "success",
        });
        navigate(ROUTES.CONFIG.LIVE_CAMERAS);
      })
      .catch(() =>
        openSnackbar({
          message: formatMessage(messages.error),
          severity: "error",
        })
      );
  };

  return (
    <Form
      title={formatMessage(id ? messages.titleEdit : messages.titleCreate)}
      editMode={!!id}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      cancelAction={ROUTES.CONFIG.LIVE_CAMERAS}
    >
      <div>
        <Controller
          control={control}
          name="company_id"
          rules={{ required: true }}
          render={({ field, fieldState: { invalid, error } }) => (
            <>
              <InputLabel>Selecione uma empresa:</InputLabel>
              <Select {...field} fullWidth value={editData.company_id}>
                {companies.map((company: ICompany, index: number) => (
                  <MenuItem value={company.id} key={`idx${index}`}>
                    {company.fantasy_name}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />
      </div>
      <div>
        <TextField
          label="URL"
          fullWidth
          {...register("url", { required: true })}
          error={Boolean(errors.url)}
          helperText={
            errors.url?.type === "required" && formatMessage(messages.required)
          }
        />
      </div>
    </Form>
  );
};

export { FormLiveCameras };
