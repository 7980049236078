import React from "react";
import {
  Container,
  Grid,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Divider,
  Chip,
  Box,
  IconButton,
  Tooltip,
  LinearProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Checkbox,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import {
  CloudDownload,
  PlayArrow,
  DownloadOutlined,
  VideocamOutlined,
  CloseOutlined,
  Grid4x4Outlined,
  LiveTv,
} from "@mui/icons-material";
import { useLocation } from "react-router-dom";

import { useSnackbarConsumer } from "shared/providers/Snackbar";
import { useIntl, defineMessages } from "react-intl";
import { FileFilters } from "modules/files/components/FileFilters";
import { downloadFile, watchFile } from "modules/files/services";
import { formatDate } from "shared/parsers/date";
import { useFileFilters } from "../../providers/FileFilters";
import { IFile } from "modules/files/types";
import { Player } from "../../components/Player";
import { BiResume } from "../../components/BiResume";
import { storeBatchDownload } from "../../services";
import { humanFileSize } from "shared/parsers/filesize";
import { VideoGrid } from "../VideoGrid";
import { VideoLiveGrid } from "../VideoLiveGrid";

const messages = defineMessages({
  downloadError: { id: "files.download.error" },
  downloadSuccess: { id: "files.download.success" },
  noSelected: { id: "files.grid.error" },
});

const FilesDashboard = () => {
  const location = useLocation();
  const isVideoList: boolean = location.pathname === "/videos";
  const {
    files,
    perPage,
    setPerPage,
    loading,
    setLoading,
    handleChangePage,
    meta,
  } = useFileFilters();

  const { openSnackbar } = useSnackbarConsumer();
  const { formatMessage } = useIntl();

  const [checkedAll, setCheckedAll] = React.useState<boolean>(false);
  const [videoOpen, setVideoOpen] = React.useState<boolean>(false);
  const [videoName, setVideoName] = React.useState<string>("");
  const [videoURL, setVideoURL] = React.useState<any>("");
  const [loadingFull, setLoadingFull] = React.useState<boolean>(false);
  const [loadingBatch, setLoadingBatch] = React.useState<boolean>(false);
  const [selectedItems, setSelectedItems] = React.useState<number[]>([]);
  const [selectedItemsNames, setSelectedItemsNames] = React.useState<string[]>(
    []
  );
  const [openBatchDownload, setOpenBatchDownload] =
    React.useState<boolean>(false);
  const [gridLoading, setGridLoading] = React.useState<boolean>(true);
  const [videosGrid, setVideosGrid] = React.useState<string[]>([]);
  const [videosGridOpen, setVideosGridOpen] = React.useState<boolean>(false);

  const [liveOpen, setLiveOpen] = React.useState<boolean>(false);

  const handleDownload = async (video: IFile, force: boolean = false) => {
    if (!video.mp4_url && !force && isVideoList) {
      setSelectedItems([video.id]);
      setSelectedItemsNames([video.name]);
      setOpenBatchDownload(true);
      return false;
    }

    setLoading(true);

    const { status, data: dataDownload } = await downloadFile(video.id);
    if (status === 200) {
      window.open(dataDownload.url);
      openSnackbar({
        message: formatMessage(messages.downloadSuccess),
        severity: "success",
      });
    } else {
      openSnackbar({
        message: formatMessage(messages.downloadError),
        severity: "error",
      });
    }
    setLoading(false);
  };

  const handleWatch = async (id: number) => {
    setLoading(true);
    const { status, data: dataWatch } = await watchFile(id);
    if (status === 200) {
      return dataWatch;
    } else {
      openSnackbar({
        message: formatMessage(messages.downloadError),
        severity: "error",
      });
    }
    setLoading(false);
  };

  const handleVideoOpen = async (file: IFile) => {
    setLoading(true);
    setLoadingFull(true);

    if (file.id) {
      const { url } = await handleWatch(file.id);
      setVideoURL(url);
      setVideoName(file.name);
      setLoadingFull(false);
      setVideoOpen(true);
    }
    setLoading(false);
  };

  const resetVideoOpen = () => {
    setVideoURL("");
    setVideoName("");
    setVideoOpen(false);
    setLoading(false);
  };

  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    let selecteds: number[] = selectedItems;
    let names: string[] = selectedItemsNames;
    const value = parseInt(event.target.value);
    const name = event.target.name;

    if (event.target.checked) {
      selecteds.push(value);
      names.push(name);
    } else {
      const key = Object.keys(selecteds).find(
        (key) => selecteds[key] === value
      );
      delete selecteds[key];

      const keyName = Object.keys(names).find((keyn) => names[keyn] === name);
      delete names[keyName];
    }

    setSelectedItems([...selecteds]);
    setSelectedItemsNames([...names]);
  };

  const handleAllChecked = React.useCallback(() => {
    if (checkedAll) {
      let selecteds: number[] = [];
      let names: string[] = [];
      files.forEach((file: IFile) => {
        selecteds.push(file.id);
        names.push(file.name);
      });
      setSelectedItems([...selecteds]);
      setSelectedItemsNames([...names]);
    } else {
      setSelectedItems([]);
      setSelectedItemsNames([]);
    }
  }, [files, checkedAll]);

  const handleBatchDownload = async () => {
    setLoadingBatch(true);

    if (!selectedItems.length) {
    }

    const { status } = await storeBatchDownload(selectedItems);
    if (status === 200) {
      window.location.href = "/downloads";
    } else {
      setLoadingBatch(false);
    }
  };

  const handleDownloadOriginalFiles = async () => {
    setLoadingBatch(true);
    for (let videoId of selectedItems) {
      const { data } = await downloadFile(videoId, true);
      if (data && data.url) {
        window.open(data.url);
        await new Promise((r) => setTimeout(r, 1000));
      }
    }
    setOpenBatchDownload(false);
    setLoadingBatch(false);
  };

  const handleBatchCancel = () => {
    setSelectedItems([]);
    setSelectedItemsNames([]);
    setOpenBatchDownload(false);
  };

  const handleVideoGrid = async () => {
    if (!selectedItems.length) {
      openSnackbar({
        message: formatMessage(messages.noSelected),
        severity: "error",
      });
      return false;
    }

    setGridLoading(true);
    setVideosGridOpen(true);
    const videos: string[] = [];
    for (let videoId of selectedItems) {
      const { data } = await watchFile(videoId);
      if (data && data.url) {
        videos.push(data.url);
      }
    }
    setVideosGrid(videos);
    setGridLoading(false);
  };

  React.useEffect(() => {
    handleAllChecked();
  }, [handleAllChecked]);

  return (
    <Container maxWidth={false}>
      <VideoLiveGrid
        open={liveOpen}
        onClose={() => setLiveOpen(false)}
        data={meta.live}
      />
      <BiResume data={meta.totalDashboard} loading={loading} />
      <Grid container alignItems="center" justifyContent="space-between">
        <Typography variant="h5" component="h4" fontWeight="bold">
          {isVideoList ? "Meus Vídeos" : "Meus Arquivos"}
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "flex-end",
            marginTop: "15px",
          }}
        >
          <FileFilters />
        </Box>
      </Grid>

      <Box sx={{ display: "flex", width: "100%" }}>
        {loading && (
          <Box sx={{ mt: 3, width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
      </Box>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 3, mb: 3 }}
      >
        <div>
          {meta && meta.live && meta.live.length > 0 && (
            <>
              <Button
                type="button"
                variant="contained"
                size="small"
                startIcon={<LiveTv />}
                onClick={() => setLiveOpen(true)}
                color="success"
              >
                Ao Vivo
              </Button>
              &nbsp;&nbsp;
            </>
          )}
          <Button
            type="button"
            variant="contained"
            size="small"
            startIcon={<DownloadOutlined />}
            onClick={() => {
              if (isVideoList) {
                setOpenBatchDownload(!openBatchDownload);
              } else {
                handleDownloadOriginalFiles();
              }
            }}
          >
            Baixar Selecionados
          </Button>
          &nbsp;&nbsp;
          <Button
            type="button"
            variant="contained"
            size="small"
            startIcon={<Grid4x4Outlined />}
            onClick={() => handleVideoGrid()}
          >
            Abrir Grade
          </Button>
        </div>
        <Box>
          Exibir{" "}
          <Select
            placeholder="Exibir"
            size="small"
            value={perPage}
            onChange={(e) => setPerPage(e.target.value)}
          >
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={150}>150</MenuItem>
            <MenuItem value={200}>200</MenuItem>
          </Select>{" "}
          por página
        </Box>
      </Grid>
      <Paper
        sx={{
          width: "100%",
          borderRadius: 4,
          boxShadow:
            "rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px",
        }}
      >
        <TableContainer>
          <Table aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell width={10}>
                  <Checkbox
                    onChange={() => setCheckedAll(!checkedAll)}
                    name="checkall"
                    checked={checkedAll}
                  />
                </TableCell>
                <TableCell>Empresa</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>Camera</TableCell>
                <TableCell>Gravado em</TableCell>
                <TableCell>Tipo</TableCell>
                <TableCell>Tamanho</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {files.length > 0 ? (
                files.map((item: IFile) => (
                  <TableRow key={item.id} hover role="checkbox" tabIndex={-1}>
                    <TableCell>
                      <Checkbox
                        onChange={handleChecked}
                        value={item.id}
                        name={item.name}
                        checked={selectedItems.includes(item.id)}
                      />
                    </TableCell>
                    <TableCell>
                      {item.company.fantasy_name || "Não definido"}
                    </TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.camera?.name || "Não definido"}</TableCell>
                    <TableCell>
                      {item.recording_dvr_date
                        ? formatDate(
                            item.recording_dvr_date,
                            "dd/MM/yyyy hh:mm:ss"
                          )
                        : "Não definido"}
                    </TableCell>
                    <TableCell>
                      <Chip label={item.fileType?.name || "Não definido"} />
                    </TableCell>
                    <TableCell>{humanFileSize(item.size || 0)}</TableCell>
                    <TableCell>
                      {isVideoList && (
                        <IconButton
                          disabled={!item.mp4_url}
                          color="primary"
                          size="small"
                          aria-label="Visualizar"
                          onClick={() => handleVideoOpen(item)}
                        >
                          <Tooltip
                            title={
                              item.mp4_url
                                ? "Assistir"
                                : "Video não disponível. Solicite a conversão."
                            }
                          >
                            <PlayArrow />
                          </Tooltip>
                        </IconButton>
                      )}
                      <IconButton
                        color="primary"
                        size="small"
                        aria-label="Baixar arquivo"
                        onClick={() => {
                          if (item.id) handleDownload(item);
                        }}
                      >
                        <Tooltip title="Fazer download">
                          <CloudDownload />
                        </Tooltip>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7}>Nenhum arquivo encontrado.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Divider />
        <TablePagination
          component="div"
          rowsPerPageOptions={[meta.per_page]}
          count={meta.total}
          rowsPerPage={meta.per_page}
          page={meta.current_page - 1}
          onPageChange={handleChangePage}
        />
      </Paper>

      <Player
        open={videoOpen}
        videoName={videoName}
        videoURL={videoURL}
        onClose={() => resetVideoOpen()}
      />
      <Dialog
        open={loadingFull}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          Aguarde. Estamos carregando o vídeo para você.
        </DialogTitle>
      </Dialog>

      <Dialog
        open={openBatchDownload}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          SOLICITAÇÃO DE DOWNLOAD
          <hr />
        </DialogTitle>

        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ textAlign: "center" }}
          >
            Os arquivos de segurança precisam ser convertidos em um formato que
            possibilite serem assistidos em seu computador. Essa conversão leva
            algum tempo para ser concluída em função do tamanho dos arquivos,
            mas você pode acompanhar a evolução na sua área de{" "}
            <a href="/downloads" target="_blank">
              Downloads
            </a>
            . Porém, se preferir, também é possível baixar no formato original,
            mas é necessário utilizar um{" "}
            <a
              href="https://dw.uptodown.com/dwn/KJfcVpQNSfCfO7xGDBbQwyQrTi45pr_miF1X8q7fDo3WDn_OmGtZqhlkjs-j7VbmCPBax2AabZ4C8FcpWwwicWHs_YCU5jWQnXaXt3yQlV6H0U-z4EWm0f-6S4Il5ihh/1La0OyNfdVtsKeZRdO7yIL9dCNtUIzMZPFuBiAW0k7yi40QLno7YsekQLMbC00N8sA7gAJqVSQWZJYZIexbszjAvMX6nQ9J_LP6du9CSjFUDqccc7SynoTzXaSbJjRQa/lscZhYKjMLMYD3wJQXY4FRdBnPc4JLFTVaa2N_akyBj_GzE8qMdhUbxKV_8SQd6SnC60akuLAzg1BF-k-IpmvHDlvxxxOls6eJDY5oGMuB4=/"
              target="_blank"
              rel="noreferrer"
            >
              player específico
            </a>{" "}
            para reprodução.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {selectedItems.length > 0 ? (
            <div
              style={{
                width: "80%",
                display: "flex",
                flexDirection: "column",
                margin: "0 auto 20px auto",
              }}
            >
              {!loadingBatch ? (
                <>
                  <Button
                    type="button"
                    variant="contained"
                    startIcon={<DownloadOutlined />}
                    onClick={() => handleDownloadOriginalFiles()}
                  >
                    Baixar Original
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    startIcon={<VideocamOutlined />}
                    onClick={() => handleBatchDownload()}
                    style={{ marginTop: "24px" }}
                  >
                    Solicitar Conversão para MP4
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    startIcon={<CloseOutlined />}
                    onClick={() => handleBatchCancel()}
                    style={{ marginTop: "24px" }}
                  >
                    Cancelar
                  </Button>
                </>
              ) : (
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                    display: "inline-flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress size={20} />
                  &nbsp;&nbsp; Aguarde...
                </p>
              )}
            </div>
          ) : (
            <h5
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "20px",
                color: "red",
              }}
            >
              Você não selecionou nenhum arquivo para download.
              <br />
              <br />
              <Button
                type="button"
                variant="contained"
                onClick={() => setOpenBatchDownload(false)}
              >
                FECHAR
              </Button>
            </h5>
          )}
        </DialogActions>
      </Dialog>
      <VideoGrid
        videos={videosGrid}
        open={videosGridOpen}
        loading={gridLoading}
        onClose={() => {
          setVideosGrid([]);
          setVideosGridOpen(false);
        }}
      />
    </Container>
  );
};

export { FilesDashboard };
