import React from "react";
import { Box } from "@mui/material";
import {
  SelectPicker,
  MultiCascader,
  InputGroup,
  Input,
  Checkbox,
} from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import { useFileFilters } from "../../providers/FileFilters";
import { ICamera } from "modules/cameras/types";
import { formatDate } from "shared/parsers/date";
import { ICompany } from "modules/companies/types";

import "rsuite/dist/rsuite.min.css";
interface IKeyValue {
  label: string;
  value: number;
}

const FileFilters: React.FC = () => {
  const { companies, cameras, tags, filters, setFilters } = useFileFilters();
  const [localCompanies, setLocalCompanies] = React.useState<IKeyValue[]>([]);
  const [localCameras, setLocalCameras] = React.useState<IKeyValue[]>([]);
  const [disabledCompanies, setDisabledCompanies] = React.useState<number[]>(
    []
  );
  // const [localTags, setLocalTags] = React.useState<IKeyValue[]>([]);

  React.useEffect(() => {
    const cm: IKeyValue[] = [];
    const cmDeleted: number[] = [];
    companies.forEach((company: ICompany) => {
      cm.push({ label: company.fantasy_name, value: company.id || 0 });
      if (
        company.fantasy_name &&
        company.deleted_at &&
        company.deleted_at !== "0000-00-00 00:00:00"
      ) {
        cmDeleted.push(company.id || 0);
      }
    });
    setLocalCompanies(cm);
    setDisabledCompanies(cmDeleted);
  }, [companies]);

  React.useEffect(() => {
    const cm: IKeyValue[] = [];
    cameras.forEach((camera: ICamera) =>
      cm.push({ label: camera.name, value: camera.id || 0 })
    );
    setLocalCameras(cm);
  }, [cameras]);

  // React.useEffect(() => {
  //   const cm: IKeyValue[] = [];
  //   tags.forEach((tag: ITag) =>
  //     cm.push({ label: tag.name, value: tag.id || 0 })
  //   );
  //   setLocalTags(cm);
  // }, [tags]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        padding: "20px 0",
        borderTop: "1px solid #CCC",
        borderBottom: "1px solid #CCC",
      }}
    >
      <form style={{ display: "flex", width: "100%", flexDirection: "column" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            marginTop: "5px",
            alignItems: "space-between",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "45%", marginRight: 10 }}>
            {/* <label>
              <b>Nome do arquivo</b>
            </label> */}
            <InputGroup>
              <Input
                placeholder="Nome do arquivo"
                value={filters.query}
                style={{ width: "100%" }}
                onChange={(query: string) => setFilters({ ...filters, query })}
              />
              <InputGroup.Addon>
                <SearchIcon />
              </InputGroup.Addon>
            </InputGroup>
          </div>
          <div style={{ width: "25%", marginRight: 10 }}>
            {/* <label>
              <b>Empresa</b>
            </label> */}
            <SelectPicker
              data={localCompanies}
              placeholder="Filtrar por empresa"
              style={{ width: "100%" }}
              onChange={(companyId: number) =>
                setFilters({ ...filters, companyId })
              }
              disabledItemValues={disabledCompanies}
            />
          </div>
          <div style={{ width: "25%", marginRight: 10 }}>
            {/* <label>
              <b>Câmeras</b>
            </label> */}
            <MultiCascader
              data={localCameras}
              style={{ width: "100%" }}
              placeholder="Filtrar por câmera"
              onChange={(values: number[]) => {
                setFilters({ ...filters, cameraIds: values });
              }}
            />
          </div>
          <div
            style={{ width: "15%", display: "flex", flexDirection: "column" }}
          >
            {/* <label>
              <b>Gravação</b>
            </label> */}
            <Checkbox
              onChange={(value, checked) => {
                setFilters({ ...filters, noRecordDate: checked });
              }}
            >
              Sem data
            </Checkbox>
          </div>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            marginTop: "25px",
          }}
        >
          <div style={{ width: "15%", marginRight: 10 }}>
            <label>
              <b>Data Inicial</b>
            </label>
            <Input
              placeholder="Data inicial"
              type="date"
              value={filters.startDate}
              style={{ width: "100%" }}
              onChange={(startDate: string) =>
                setFilters({ ...filters, startDate, endDate: startDate })
              }
            />
          </div>
          <div style={{ width: "15%", marginRight: 10 }}>
            <label>
              <b>Hora Inicial</b>
            </label>
            <Input
              placeholder="Hora inicial"
              type="time"
              value={filters.startTime}
              style={{ width: "100%" }}
              onChange={(startTime: string) =>
                setFilters({ ...filters, startTime })
              }
            />
          </div>
          <div style={{ width: "15%", marginRight: 10 }}>
            <label>
              <b>Data Final</b>
            </label>
            <Input
              placeholder="Data Final"
              type="date"
              value={filters.endDate || formatDate(new Date(), "yyyy-MM-dd")}
              style={{ width: "100%" }}
              onChange={(endDate: string) =>
                setFilters({ ...filters, endDate })
              }
            />
          </div>
          <div style={{ width: "15%", marginRight: 10 }}>
            <label>
              <b>Hora Final</b>
            </label>
            <Input
              placeholder="Hora Final"
              type="time"
              value={filters.endTime}
              style={{ width: "100%" }}
              onChange={(endTime: string) =>
                setFilters({ ...filters, endTime })
              }
            />
          </div>
        </Box>

        {/* <div>
          <MultiCascader
            data={localTags}
            style={{ width: 225 }}
            placeholder="Filtrar por etiquetas/tags"
            onChange={(tags: any) => setFilters({ ...filters, tags })}
          />
        </div> */}
      </form>
    </Box>
  );
};

export { FileFilters };
