import { ROUTES } from "config/routes";
import { ICONS_LABEL } from "shared/constants/icons";

const manager = [
  {
    name: "Home",
    url: ROUTES.HOME,
    icon: ICONS_LABEL.Home,
    submenu: [],
  },
  {
    name: "Gerenciador de Arquivos",
    submenu: [
      {
        name: "Meus Videos",
        url: ROUTES.VIDEOS,
        icon: ICONS_LABEL.CloudDoneOutlined,
      },
      {
        name: "Meus Arquivos",
        url: ROUTES.FILES,
        icon: ICONS_LABEL.AttachFileOutlined,
      },
      {
        name: "Downloads",
        url: ROUTES.DOWNLOADS,
        icon: ICONS_LABEL.CloudDownloadOutlined,
      },
      {
        name: "Registros",
        url: ROUTES.DOWNLOADS_LOGS,
        icon: ICONS_LABEL.TimelapseOutlined,
      },
    ],
  },
  {
    name: "Administrativo",
    url: null,
    icon: ICONS_LABEL.AdminPanelSettings,
    submenu: [
      {
        name: "Usuários",
        url: ROUTES.ADMIN.USERS,
        icon: ICONS_LABEL.Person,
      },
      {
        name: "Acessos",
        url: ROUTES.ACCESS_LOGS,
        icon: ICONS_LABEL.TimelapseOutlined,
      },
    ],
  },
  {
    name: "Configurações",
    url: ROUTES.CONFIG.ROOT,
    icon: ICONS_LABEL.Settings,
    submenu: [
      {
        name: "Câmeras",
        url: ROUTES.CONFIG.CAMERAS,
        icon: ICONS_LABEL.CameraAltOutlined,
      },
    ],
  },
  // {
  //   name: "Relatórios",
  //   url: null,
  //   icon: ICONS_LABEL.AdminPanelSettings,
  //   submenu: [],
  // },
  // {
  //   name: "Configurações",
  //   url: ROUTES.CONFIG.ROOT,
  //   icon: ICONS_LABEL.Settings,
  //   submenu: [
  //     {
  //       name: "Cameras",
  //       url: ROUTES.CONFIG.CAMERAS,
  //       icon: ICONS_LABEL.CameraAltOutlined,
  //     },
  //     {
  //       name: "Tags/Etiquetas",
  //       url: ROUTES.CONFIG.TAGS,
  //       icon: ICONS_LABEL.LocalOfferOutlined,
  //     },
  //   ],
  // },
];

export default manager;
