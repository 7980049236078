import { Dashboard } from "modules/dashboard";
import { FileFiltersProvider } from "modules/files/providers/FileFilters";
import withAuthenticationRequired from "shared/hocs/with-authentication-required";
import withSideBar from "shared/hocs/with-side-bar";

const Page = function (): React.ReactElement {
  return (
    <FileFiltersProvider>
      <Dashboard />
    </FileFiltersProvider>
  );
};

export default withAuthenticationRequired(withSideBar(Page));
