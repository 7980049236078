import { ROUTES } from "config/routes";
import { ICONS_LABEL } from "shared/constants/icons";

const user = [
  {
    name: "Home",
    url: ROUTES.HOME,
    icon: ICONS_LABEL.Home,
    submenu: [],
  },
  {
    name: "Gerenciador de Arquivos",
    submenu: [
      {
        name: "Meus Videos",
        url: ROUTES.VIDEOS,
        icon: ICONS_LABEL.CloudDoneOutlined,
      },
      {
        name: "Meus Arquivos",
        url: ROUTES.FILES,
        icon: ICONS_LABEL.AttachFileOutlined,
      },
      {
        name: "Downloads",
        url: ROUTES.DOWNLOADS,
        icon: ICONS_LABEL.CloudDownloadOutlined,
      },
    ],
  },
  // {
  //   name: "Relatórios",
  //   url: null,
  //   icon: ICONS_LABEL.AdminPanelSettings,
  //   submenu: [
  //     {
  //       name: "Relatório de downloads",
  //       url: ROUTES.ADMIN.COMPANIES,
  //       icon: ICONS_LABEL.ApartmentOutlined,
  //     },
  //   ],
  // },
];

export default user;
