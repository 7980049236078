const logs = {
  "TRANSCODE-REQUEST": "Conversão Solicitada",
  "TRANSCODE-COMPLETED": "Conversão Finalizada",
  "TRANSCODE-CANCELED": "Conversão Cancelada",
  DOWNLOAD: "Solicitação de download",
  WATCH: "Reprodução de vídeo",
  LOGIN: "Login",
};

export default logs;
