import React from "react";

import { FormLiveCameras } from "modules/live-cameras/components/Form";

import withAuthenticationRequired from "shared/hocs/with-authentication-required";
import withSideBar from "shared/hocs/with-side-bar";

const Page: React.FC = () => {
  return <FormLiveCameras />;
};

export default withAuthenticationRequired(withSideBar(Page));
