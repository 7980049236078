import styled from "@emotion/styled";

export const Container = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  margin-top: 30px;
`;

export const Chart = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
`;

export default Container;
