import React from "react";
import { Container } from "@mui/material";
import { ROUTES } from "config/routes";
import { List } from "shared/components/List";
import { useIntl, defineMessages } from "react-intl";
import { columns } from "./columns";

const messages = defineMessages({
  title: { id: "live.cameras" },
});

const ListLiveCameras: React.FC = () => {
  const { formatMessage } = useIntl();
  return (
    <Container maxWidth="lg">
      <List
        title={formatMessage(messages.title)}
        columns={columns}
        uri={ROUTES.CONFIG.LIVE_CAMERAS}
      />
    </Container>
  );
};

export { ListLiveCameras };
