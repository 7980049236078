import axiosInstance from "shared/services";
import { ICompanies } from "modules/companies/types";

const uri = "companies";

export const all = async (
  filterCompanies: boolean = true
): Promise<ICompanies> =>
  axiosInstance
    .get(`${uri}/all?filterCompanies=${filterCompanies ? 1 : 0}`)
    .then();

export const getDashboard = async (filters: {
  companyId: number;
  month: string;
}) =>
  axiosInstance.get(`${uri}/dashboard/${filters.companyId}/${filters.month}`);
