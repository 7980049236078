import React from "react";
import { Modal, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

import * as Styled from "./styles";
import { all } from "modules/live-cameras/services";

interface IProps {
  data: [];
}

const VideoLiveGrid = ({ open, onClose, data = [] }) => {
  const [videoWidth, setVideoWidth] = React.useState<string>("640");

  React.useEffect(() => {
    if (open) document.body.requestFullscreen();
  }, [open]);

  const handleResize = () => {
    setVideoWidth(String(window.innerWidth / 4));
  };

  window.addEventListener("resize", handleResize);

  return (
    <Modal open={open} onClose={() => onClose()}>
      <Styled.Main>
        <Styled.ButtonClose
          onClick={() => {
            onClose();
            document.exitFullscreen();
          }}
        >
          <Close />
        </Styled.ButtonClose>
        <Styled.Grid id="parent">
          {data &&
            data.map((video: any, index: number) => (
              <iframe
                width={videoWidth}
                src={video.url}
                title="Live"
                frameBorder={0}
              />
            ))}
        </Styled.Grid>

        {/* <>
          {!loading ? (
            <Styled.Grid>
              <iframe
                width="100%"
                height="100%"
                src="/live/index.html"
                title="Live"
                frameBorder={0}
              />
            </Styled.Grid>
          ) : (
            <Styled.Loading>
              <Typography>Aguarde...</Typography>
            </Styled.Loading>
          )}
        </> */}
      </Styled.Main>
    </Modal>
  );
};

export { VideoLiveGrid };
