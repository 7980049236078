import { formatDate } from "shared/parsers/date";

export interface Column {
  id: "id" | "url" | "company" | "created_at" | "updated_at" | "options";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: any) => string;
}

const columns: Column[] = [
  {
    id: "id",
    label: "ID",
    minWidth: 80,
  },
  {
    id: "url",
    label: "URL",
    minWidth: 170,
  },
  {
    id: "company",
    label: "Empresa",
    format: (value) => {
      return value.fantasy_name;
    },
  },
  {
    id: "created_at",
    label: "Criado em",
    minWidth: 170,
    align: "right",
    format: formatDate,
  },
  {
    id: "updated_at",
    label: "Atualizado em",
    minWidth: 170,
    align: "right",
    format: formatDate,
  },
  {
    id: "options",
    label: "Ações",
    minWidth: 170,
    align: "right",
  },
];

export { columns };
