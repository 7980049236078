import React from "react";
import { DashboardChart } from "./components/chart";
import { SelectPicker } from "rsuite";
import { months } from "../../shared/constants/months";
import * as Styled from "../../modules/dashboard/styles";
import { useFileFilters } from "modules/files/providers/FileFilters";
import { ICompany, ICompanyDashboardItem } from "modules/companies/types";
import { getDashboard } from "modules/companies/services";
import { CircularProgress } from "@mui/material";

interface IKeyValue {
  label: string;
  value: number;
}

interface IFilters {
  companyId: number;
  month: string;
}

const Dashboard: React.FC = () => {
  const { companies } = useFileFilters();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [filters, setFilters] = React.useState<IFilters>({} as IFilters);
  const [localCompanies, setLocalCompanies] = React.useState<IKeyValue[]>([]);
  const [data, setData] = React.useState<ICompanyDashboardItem>({
    days: [],
    gb: [],
    files: [],
  });

  const handleData = React.useCallback(async () => {
    setLoading(true);
    const { status, data: dataDashboard } = await getDashboard(filters);

    if ([200, 201].includes(status)) {
      setData(dataDashboard);
      setLoading(false);
    }
  }, [filters]);

  React.useEffect(() => {
    const cm: IKeyValue[] = [];
    companies.forEach((company: ICompany, index: number) => {
      if (index === 0) {
        setFilters({
          companyId: company.id,
          month: (new Date().getMonth() + 1).toString(),
        });
      }
      cm.push({ label: company.fantasy_name, value: company.id || 0 });
    });
    setLocalCompanies(cm);
  }, [companies]);

  React.useEffect(() => {
    handleData();
  }, [handleData]);

  return (
    <Styled.Container>
      <h4>Resumo</h4>
      <Styled.Header>
        <div style={{ width: "25%", marginRight: 10 }}>
          <label>
            <b>Empresa</b>
          </label>
          <SelectPicker
            data={localCompanies}
            placeholder="Filtrar por empresa"
            style={{ width: "100%" }}
            onChange={(companyId: number) =>
              setFilters({ ...filters, companyId })
            }
          />
        </div>
        <div style={{ width: "25%", marginRight: 10 }}>
          <label>
            <b>Mês</b>
          </label>
          <SelectPicker
            data={months}
            placeholder="Filtrar por mês"
            style={{ width: "100%" }}
            onChange={(month: string) => setFilters({ ...filters, month })}
          />
        </div>
        <div
          style={{
            width: "50%",
            marginRight: 10,
            display: "flex",
            alignItems: "center",
            gap: "10px",
            paddingTop: "20px",
          }}
        >
          {loading && (
            <>
              <CircularProgress size={15} />
              Aguarde...
            </>
          )}
        </div>
      </Styled.Header>
      <Styled.Chart>
        {!loading && (
          <div style={{ display: "flex", width: "750px" }}>
            <DashboardChart values={data} />
          </div>
        )}
      </Styled.Chart>
    </Styled.Container>
  );
};

export { Dashboard };
