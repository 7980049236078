import axios, { AxiosRequestConfig } from "axios";

import { Auth, APIError, RecoveryPassword, UpdatePassword } from "shared/types";
import { IUserResponse } from "modules/users/types";

import { getPropData } from "shared/parsers";
import { getAuthenticatedUser } from "shared/parsers/localstorage";

import PROPERTIES from "config/properties";

const axiosInstance = axios.create({
  baseURL: PROPERTIES.API_URL,
});

axiosInstance.interceptors.request.use(function (config: AxiosRequestConfig) {
  const token = getAuthenticatedUser()?.auth?.token;

  if (token && config) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      },
    };
  }

  return config;
});

axiosInstance.interceptors.response.use(undefined, (error) => {
  if (error.response && error.response.status === 401) {
    if (
      !["/login", "/login/forgot-password", "/login/reset-password"].includes(
        window.location.pathname
      )
    ) {
      window.location.href = "/login";
      localStorage.clear();
    }
  }

  return error;
});

export const login = (
  email: string,
  password: string
): PromiseLike<Auth | APIError> => {
  return axiosInstance
    .post<Auth | Error>("/login", {
      email,
      password,
    })
    .then(getPropData);
};

export const recovery = async (email: string): Promise<RecoveryPassword> => {
  return axiosInstance.post("/recovery-password-email", {
    email,
  });
};

export const updatePassword = async (
  data: { password: string; password_confirmation: string },
  token: string
): Promise<UpdatePassword> => {
  return axiosInstance.post("/change-password", { ...data, token });
};

export const validateSignature = async (
  email: string,
  signature: string
): Promise<UpdatePassword> => {
  return axiosInstance.post(
    `/recovery-password-signature/${email}?signature=${signature}`
  );
};

export const me = async (): Promise<IUserResponse> => {
  return axiosInstance.get("/me");
};

export const firstAccess = async (data: {
  password: string;
  passwordConfirmation: string;
}): Promise<UpdatePassword> => {
  return axiosInstance.post("/first-access", data);
};

export default axiosInstance;
