import React from "react";
import { ICamera } from "modules/cameras/types";
import { all } from "modules/cameras/services";

const useCameras = (companyId?: number) => {
  const [cameras, setCameras] = React.useState<ICamera[]>([]);

  const getAll = React.useCallback(async () => {
    if (companyId) {
      const { data } = await all(companyId || null);
      setCameras(data);
    }
  }, [companyId]);

  React.useEffect(() => {
    getAll();
  }, [getAll]);

  return { cameras };
};

export { useCameras };
