import React from "react";
import { FirstLogin } from "modules/auth/components/FirstLogin";
import * as Styled from "./styles";

const Page: React.FC = () => {
  return (
    <Styled.Container>
      <FirstLogin />
    </Styled.Container>
  );
};

export default Page;
